<template>
	<el-container>
		<y-header></y-header>
		<el-main>
			<div class="page-container">
				<div class="index-cont">
					<img :src="$staticUrl + 'images/ico-success.png'" class="success-icon" />
					<div>
						<span class="success-title">欢迎您，{{ user.linkPerson }}</span>
						<span class="success-text" v-if="user.status != '3' && user.isUseDemoUser == 1 && isCreatelegalPerson == 1 && !$isMobile">
							您可以登录试用体验悠租云，也可以直接开始您的商户配置，配置完成后即可正式开店
						</span>
						<span class="success-text" v-if="user.status != '3' && user.isUseDemoUser == 1 && isCreatelegalPerson != 1 && !$isMobile">
							您可以点击按钮登录试用系统
						</span>
						<span class="success-text" v-if="user.status != '3' && user.isUseDemoUser != 1 && isCreatelegalPerson == 1 && !$isMobile">
							您可以点击按钮开始配置您的商户
						</span>
						<span class="success-text" v-if="user.status != '3' && user.isUseDemoUser != 1 && isCreatelegalPerson != 1 && !$isMobile">
							如果您想试用系统，请电话联系客户经理开通试用功能
						</span>
						<span class="success-text" v-if="user.status == '3' && !$isMobile">您已经入驻悠租云</span>
						<span class="success-text" v-if="$isMobile && user.status != '0'"
							>您的账号已开通，可以登录以下网址，选择试用悠租云，或者配置您的正式商户。<br />网址：https://console.yozuyun.com/</span
						>
						<div class="fr-c" v-if="!$isMobile">
							<el-button type="cancel" v-if="user.status == '0'" @click="go(-1)">返回</el-button>

							<el-button type="cancel" v-if="user.isUseDemoUser == 1" @click="modalShow = true">开始试用</el-button>
							<el-button type="primary" v-if="isCreatelegalPerson == 1 && user.status != '3'" @click="settingShow = true">开始配置我的商户</el-button>

							<el-button type="cancel" v-if="user.status == '3'" @click="goPlatform">登录悠租云平台</el-button>
							<!-- <el-button type="primary" v-if="user.status == '3' && isOk == '3'" @click="go({ name: 'initialSetting' })">修改配置</el-button> -->
						</div>
					</div>
					<div class="mg-t20" v-if="isOperater">
						<span>您是悠租云运营人员，可以在下方输入商机名称切换到对应的商户进行配置。</span>
						<div class="mg-t20 dis-f fr-c">
							<div class="ws-n mg-t8">商机名称：</div>
							<el-select v-model="searchMobile" clearable filterable placeholder="请输入名称">
								<el-option v-for="(item,index) in options" :label="item.enterprisePerson" :key="index" :value="item.mobile"></el-option>
							</el-select>
							<el-button type="primary" @click="changeBusiness" class="mg-l10">切换至该商户</el-button>
						</div>
					</div>
				</div>
			</div>
		</el-main>
		<y-footer></y-footer>
		<el-dialog title="开通提示" :visible.sync="settingShow" width="50rem" top="30vh" center>
			<div>在配置之前，请准备好如下资料，以方便您更快的完成配置。</div>
			<div>1，营业执照</div>
			<div>2，法人身份证</div>
			<div>3，对公户开户许可证（可选）</div>
			<div>4，授权书及印章采集扫描件</div>
			<a href="http://homeplus.oss-cn-hangzhou.aliyuncs.com/homeplus/C95BE2B6-4D20-0001-C88D-16001E3E24F0" class="f12 mg-r30">点击下载授权书及印章采集模板</a>
			<span slot="footer" class="dialog-footer">
				<div class="dis-f fr-c mg-b10">
					<el-button @click="settingShow = false">取 消</el-button>
					<el-button type="primary" @click="go({ name: 'initialSetting' })">已准备好，开始配置</el-button>
				</div>
				<router-link v-if="isOk > 0" class="c-grey" :to="{ name: 'initialSetting', query: { step: isOk } }"
					>您上次已经配置到第{{ stepName[isOk] }}步，直接跳转继续配置。</router-link
				>
			</span>
		</el-dialog>
		<el-dialog title="请选择试用的角色" :visible.sync="modalShow" width="33rem" top="30vh" center>
			<el-radio-group v-model="tryRadio" class="roles-cont">
				<el-radio v-for="item in FVs['20210420004']" :key="item.code" :label="item.code">{{ item.name }}</el-radio>
			</el-radio-group>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="goPage">确 定</el-button>
			</span>
		</el-dialog>
	</el-container>
</template>
<script>
import { mapState } from "vuex"

export default {
	computed: { ...mapState(["FVs", "user", "login"]) },
	data() {
		return {
			settingDone: false,
			settingShow: false,
			modalShow: false,
			tryRadio: "",
			isOk: 0,
			stepName: ["一", "二", "三"],
			elemIF: null,
			isCreatelegalPerson: null,
			isOperater:false,//查询是否属于运营人员 2022-9-24
			searchMobile:"",
			options:[],
		}
	},
	created() {},
	mounted() {
		if (this.user.status > 0) {
			this.getProcessDetail()
		}
		let tryList = this.FVs["20210420004"]
		this.isOperater = this.FVs['202209230001'].some(item => item.code == this.user.mobile) //查询是否属于运营人员 2022-9-24
		this.getBusiness()
		this.tryRadio = tryList ? tryList[0].code : ""
	},
	methods: {
		getProcessDetail() {
			// 获取用户之前进行到流程的哪一步
			this.$Xhr({
				url: "/ptBusinessEnterpriseInfo/detail",
				data: {},
				method: "post"
			}).then(res => {
				this.isOk = res.isOk
				this.isCreatelegalPerson = res.isCreatelegalPerson
			})
		},
		goPage() {
			this.$Xhr({
				url: "/ptBusinessEnterpriseInfo/getOperationUrl",
				data: { userName: this.tryRadio },
				method: "post"
			}).then(res => {
				let self = this
				try {
					if (self.elemIF) {
						document.body.removeChild(self.elemIF)
					}
					var elemIF = document.createElement("iframe")
					elemIF.setAttribute("sandbox", "allow-forms allow-scripts allow-same-origin allow-popups")
					elemIF.src = res.logoutUrl
					elemIF.style.display = "none"
					self.elemIF = elemIF
					document.body.appendChild(elemIF)
					self.modalShow = false
					setTimeout(() => {
						location.href = res.url
					}, 500)
				} catch (e) {
					console.log("failed: clear")
				}
			})
		},
		goPlatform() {
			this.$Xhr({
				url: "/ptBusinessEnterpriseInfo/getLoginPcUrl",
				data: {}
			}).then(res => {
				let self = this
				try {
					if (self.elemIF) {
						document.body.removeChild(self.elemIF)
					}
					var elemIF = document.createElement("iframe")
					elemIF.setAttribute("sandbox", "allow-forms allow-scripts allow-same-origin allow-popups")
					elemIF.src = res.logoutUrl
					elemIF.style.display = "none"
					self.elemIF = elemIF
					document.body.appendChild(elemIF)
					setTimeout(() => {
						location.href = res.url
					}, 500)
				} catch (e) {
					console.log("failed: clear")
				}
			})
		},
		//查询商机
		getBusiness() {
			this.$Xhr({
				url: "/userLogin/queryBusinessByMobileOrName",
				data: {mobile: ""},
				method: "post"
			}).then(res => {
				this.options = res
			})
		},
		//
		changeBusiness(){
			this.$Xhr({
				url: "/userLogin/switchAccount",
				data: {mobile: this.searchMobile},
				method: "post"
			}).then(res => {
				this.$store.commit("updateUser", res)
				this.$store.commit("updateLoginStatus", true)
				setTimeout(() => {
					this.$router.go(0)
				}, 300)
			})
		}
	}
}
</script>
<style lang="scss" scoped>
.el-main {
	display: flex;
	flex-direction: column;
}
.page-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
}
.index-cont {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-bottom: 10rem;
}
.success-icon {
	width: 10.4rem;
	height: 10.4rem;
	margin-bottom: 2.8rem;
}
.success-text {
	color: #999;
	width: 46rem;
	text-align: center;
	display: flex;
	align-items: center;
	margin: 1.2rem 0 5rem;
	justify-content: center;
	@media screen and (max-width: 1024px) {
		width: 31rem;
	}
}
.success-title {
	color: #333333;
	font-size: 2rem;
	font-weight: bold;
	display: flex;
	justify-content: center;
}
.roles-cont {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-left: 0;
	padding: 0 0 0 2rem;
	.el-radio {
		margin-right: 0;
		width: 50%;
	}
}
</style>
